// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ImpersonationBanner.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ImpersonationBanner.tsx");
  import.meta.hot.lastModified = "1727919614026.7168";
}
// REMIX HMR END

import { Form } from "@remix-run/react";
import { Button } from "./primitives/Buttons";
import { UserMinusIcon } from "@heroicons/react/20/solid";
export function ImpersonationBanner() {
  return <div className="w-full">
      <Form action="/resources/impersonation" method="delete" reloadDocument className="w-full">
        <Button type="submit" variant="small-menu-item" LeadingIcon={UserMinusIcon} fullWidth textAlignLeft>
          Stop impersonating
        </Button>
      </Form>
    </div>;
}
_c = ImpersonationBanner;
var _c;
$RefreshReg$(_c, "ImpersonationBanner");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;