// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/billing/v3/UpgradePrompt.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/billing/v3/UpgradePrompt.tsx");
  import.meta.hot.lastModified = "1727919614028.6768";
}
// REMIX HMR END

import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import tileBgPath from "~/assets/images/error-banner-tile@2x.png";
import { useOrganization } from "~/hooks/useOrganizations";
import { useCurrentPlan } from "~/routes/_app.orgs.$organizationSlug/route";
import { v3BillingPath } from "~/utils/pathBuilder";
import { LinkButton } from "../../primitives/Buttons";
import { Icon } from "../../primitives/Icon";
import { Paragraph } from "../../primitives/Paragraph";
import { DateTime } from "~/components/primitives/DateTime";
export function UpgradePrompt() {
  _s();
  const organization = useOrganization();
  const plan = useCurrentPlan();
  if (!plan || !plan.v3Usage.hasExceededFreeTier) {
    return null;
  }
  const nextMonth = new Date();
  nextMonth.setUTCMonth(nextMonth.getMonth() + 1);
  nextMonth.setUTCDate(1);
  nextMonth.setUTCHours(0, 0, 0, 0);
  return <div className="flex h-10 items-center justify-between border border-error bg-repeat py-0 pl-3 pr-2" style={{
    backgroundImage: `url(${tileBgPath})`,
    backgroundSize: "8px 8px"
  }}>
      <div className="flex items-center gap-2">
        <Icon icon={ExclamationCircleIcon} className="h-5 w-5 text-error" />
        <Paragraph variant="small" className="text-error">
          You have exceeded the monthly $
          {(plan.v3Subscription?.plan?.limits.includedUsage ?? 500) / 100} free credits. No runs
          will execute in Prod until{" "}
          <DateTime date={nextMonth} includeTime={false} timeZone="utc" />, or you upgrade.
        </Paragraph>
      </div>
      <LinkButton variant={"primary/small"} leadingIconClassName="px-0" to={v3BillingPath(organization)}>
        Upgrade
      </LinkButton>
    </div>;
}
_s(UpgradePrompt, "7jvQ6AuC/oP4PcDexifaSPP7K4U=", false, function () {
  return [useOrganization, useCurrentPlan];
});
_c = UpgradePrompt;
export function useShowUpgradePrompt(organization) {
  _s2();
  const currentPlan = useCurrentPlan();
  const shouldShow = currentPlan?.v3Usage.hasExceededFreeTier === true;
  return {
    shouldShow
  };
}
_s2(useShowUpgradePrompt, "fabr9oBEYy1nSGh3a9PhJiViEtQ=", false, function () {
  return [useCurrentPlan];
});
var _c;
$RefreshReg$(_c, "UpgradePrompt");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;